<template>
  <div id="app" class="construction-page">
    <img src="@/assets/ilustracja.png" alt="maramara Design Logo" class="logo" />
    <a href="https://www.instagram.com/maramaradesign/" target="_blank" class="instagram-link">
      <img src="@/assets/instagram.png" alt="Instagram Icon" />
    </a>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
  html, body {
    margin: 0;
    padding: 0;
    height: 100%; /* Make sure body takes full height of the viewport */
  }
</style>

<style scoped>
.construction-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100vh;
  background-color: #fff6d0;
  color: #333;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  box-sizing: border-box;
}

.logo {
  width: 100%;  /* Make logo responsive and fit the container */
  max-width: 700px; /* Limit the max width of the logo to prevent it from becoming too large */
  height: auto;
  object-fit: contain; /* Ensure the image scales correctly without distortion */
  margin-bottom: 20px;
}

.instagram-link {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #333;
  font-size: 1.2em;
}

.instagram-link img {
  width: 10vw; /* Set the Instagram icon to be 10% of the viewport width */
  height: auto;
  max-width: 40px; /* Limit the max width of the Instagram icon */
  max-height: 40px; /* Limit the max height of the Instagram icon */
}

@media (max-width: 768px) {
  .logo {
    max-width: 100%; /* Limit logo width for medium screens */
  }

  .instagram-link {
    font-size: 1em; /* Adjust font size */
  }

  .instagram-link img {
    width: 12vw; /* Adjust Instagram icon width for medium screens */
    max-width: 35px; /* Set max width */
  }
}

@media (max-width: 480px) {
  .logo {
    max-width: 100%; /* Limit logo width on small screens */
  }

  .instagram-link img {
    width: 15vw; /* Set Instagram icon to be 15% of the screen width */
    max-width: 30px; /* Max width */
  }
}
</style>
